import { useRef, useState } from 'react';
import { postApi } from '../utils/api';
import { rangeBookingApi } from '../utils/endpoint';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { setHours, setMinutes, format } from 'date-fns';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RangeBookingForm = ({ selectedRange, setTimeoutId, timeoutId }) => {
  const { personalDetail } = useSelector((state) => state.signup);

  const today = new Date().toISOString().split('T')[0].toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [noOfParticipants, setNoOfParticipants] = useState(1);
  const [skillLevel, setSkillLevel] = useState('Novice/Never Ever');
  const [firearm, setFirearm] = useState('Handgun');
  const [rangePolicy, setRangePolicy] = useState(false);
  const [notes, setNotes] = useState('');
  const [isBookLoading, setIsBookLoading] = useState(false);
  const [isBookingDone, setIsBookingDone] = useState({
    msgText: '',
    isSuccess: false,
  });
  const access_token = Cookies.get('userToken');
  const [availableDays, setAvailableDays] = useState([]);
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  let timeoutIdDemo;
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNo: '',
    gender: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  console.log(personalDetail);
  const handleBook = (e) => {
    e.preventDefault();
    setIsBookingDone({
      msgText: '',
      isSuccess: false,
    });

    const participantArray = [
      {
        fullName: personalDetail?.name,
        email: personalDetail?.email,
        phoneNo: personalDetail?.telephone,
        gender: personalDetail?.gender,
      },
    ];

    if (noOfParticipants === '2') {
      participantArray.push(formData);
    }

    if (
      formData.email === personalDetail?.email ||
      formData.phoneNo === personalDetail?.telephone
    ) {
      let errorMessage = 'Participant details cannot be same as yours.';

      if (
        formData.email === personalDetail?.email &&
        formData.phoneNo !== personalDetail?.telephone
      ) {
        errorMessage =
          'The email you provided is already associated with an account. ';
      }

      if (
        formData.phoneNo === personalDetail?.telephone &&
        formData.email !== personalDetail?.email
      ) {
        errorMessage =
          'The phone number you provided is already associated with an account. ';
      }

      setIsBookingDone({
        msgText: errorMessage,
        isSuccess: false,
      });
      setIsBookLoading(false);
      return;
    }

    if (rangePolicy) {
      setIsBookLoading(true);
      const formdata = new FormData();
      formdata.append('range_id', selectedRange?.id);
      formdata.append('date', format(new Date(date), 'dd-MM-yyyy'));
      formdata.append('time', format(new Date(time), 'h:mm aa'));
      formdata.append('no_of_participants', noOfParticipants);
      formdata.append('participants', JSON.stringify(participantArray));
      formdata.append('firearm', firearm);
      formdata.append('skill_level', skillLevel);
      formdata.append('notes', notes);
      postApi(rangeBookingApi, formdata, access_token)
        .then((res) => {
          if (res?.data?.success) {
            setIsBookingDone({
              msgText:
                'Your reservation request is submitted successfully. Our range rep will contact you soon. Thank you!',
              isSuccess: true,
            });

            timeoutIdDemo = setTimeout(() => {
              setIsBookingDone({
                msgText: '',
                isSuccess: false,
              });
              const modalClose =
                document.getElementsByClassName('cancel-booking')[0];
              modalClose.click();
            }, 5000);
            setTimeoutId(timeoutIdDemo);
          }
          setIsBookLoading(false);
        })
        .catch((err) => {
          setIsBookingDone({
            msgText: err?.response?.data.message,
            isSuccess: false,
          });
          setIsBookLoading(false);
        });
    } else {
      setIsBookingDone({
        msgText: 'Please read and agree to Range Policies.',
        isSuccess: false,
      });
      setIsBookLoading(false);
    }
  };

  const handleClose = () => {
    setDate('');
    clearTimeout(timeoutId);
    setTime('');
    setNoOfParticipants(1);
    setNotes('');
    setTimeoutId();
    setIsBookingDone({
      msgText: '',
      isSuccess: false,
    });
    setFirearm('Pistol Red-Dot');
    setSkillLevel('Novice/Never Ever');
    setRangePolicy(false);
    setFormData({
      fullName: '',
      email: '',
      phoneNo: '',
      gender: '',
    });
  };

  useEffect(() => {
    setAvailableDays([]);
    if (Object.keys(selectedRange).length > 0) {
      // Filter out closed days
      const closeDays = selectedRange?.range_hours.filter(
        (day) => day.closed === 1
      );
      if (closeDays.length > 0) {
        let excludelistmap = closeDays.map((item) => {
          let excludelist = generateExcludedDatesForDayOfWeek(Number(item.day));
          return excludelist;
        });
        const allExcludedDates = excludelistmap.flat();
        setAvailableDays(allExcludedDates);
      }
    }
  }, [selectedRange]);

  const generateExcludedDatesForDayOfWeek = (dayIndex) => {
    const excludedDates = [];
    // Loop through each month of the year
    for (let month = 0; month < 12; month++) {
      // Calculate the start and end dates of the current month
      const startDate = new Date(new Date().getFullYear(), month, 1);
      const endDate = new Date(new Date().getFullYear(), month + 1, 0);
      for (
        let currentDate = new Date(startDate);
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        const adjustedDayIndex = (currentDate.getDay() + 6) % 7;
        // Check if the current day matches the specified dayIndex (0 for Monday, 1 for Tuesday, ..., 6 for Sunday)
        if (adjustedDayIndex === dayIndex) {
          excludedDates.push(new Date(currentDate));
        }
      }
    }

    return excludedDates;
  };

  const handleDate = (date) => {
    const currentDate = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
      })
    );
    const selectedDay = (date.getDay() + 6) % 7;
    const availableHoursForDay = selectedRange?.range_hours.find(
      (range) => Number(range.day) === selectedDay
    );

    if (availableHoursForDay) {
      let minDateTime = new Date();
      let maxDateTime = new Date();
      const startTimeArray = availableHoursForDay?.start_time
        .split(':')
        .map((str) => parseInt(str, 10));
      const currentHours = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();
      if (
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear()
      ) {
        // If the selected date is the current day, set minimum time to the current time
        if (
          currentHours > startTimeArray[0] ||
          (currentHours === startTimeArray[0] &&
            currentMinutes >= startTimeArray[1])
        ) {
          minDateTime = currentDate;
        } else {
          minDateTime = new Date(
            setHours(
              setMinutes(
                new Date(),
                availableHoursForDay?.start_time.split(':')[1]
              ),
              parseInt(availableHoursForDay?.start_time.split(':')[0], 10)
            )
          );
        }
      } else {
        minDateTime = new Date(
          setHours(
            setMinutes(
              new Date(),
              availableHoursForDay?.start_time.split(':')[1]
            ),
            parseInt(availableHoursForDay?.start_time.split(':')[0], 10)
          )
        );
      }

      // Set maximum time
      maxDateTime = new Date(
        setHours(
          setMinutes(new Date(), availableHoursForDay?.end_time.split(':')[1]),
          parseInt(availableHoursForDay?.end_time.split(':')[0], 10)
        )
      );

      setMinTime(minDateTime);
      setMaxTime(maxDateTime);
    }
    setDate(date);
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsBookLoading(true);
      // Loop through each form element and remove error class
      Array.from(form.elements).forEach((element) => {
        element.classList.remove('error-input-border-red');
      });
      await handleBook(e);
    } else {
      // Loop through each form element and add error class if invalid
      Array.from(form.elements).forEach((element) => {
        if (!element.checkValidity()) {
          element.classList.add('error-input-border-red');
        } else {
          element.classList.remove('error-input-border-red');
        }
      });
      form.reportValidity();
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title h-m" id="exampleModalLabel">
          {selectedRange?.name}
        </h5>
        <button
          type="button"
          className="btn-close cancel-booking"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <form ref={formRef}>
        <div className="modal-body ">
          <div className="d-flex gap-md-3 pb-0 m-b">
            <div className="floating-group select-floating w-100 mb-f">
              <DatePicker
                selected={date}
                required
                onChange={(date) => handleDate(date)}
                className="form-control"
                placeholderText="dd-mm-yyyy"
                excludeDates={availableDays}
                dateFormat="dd-MM-yyyy"
                disabled={isBookingDone?.isSuccess}
                minDate={today}
              />
              <label htmlFor="name" className="form-label label-text">
                Choose Date
              </label>
              {/* <div className="icon-form">
                <i className="fa fa-calendar-check-o"></i>
              </div> */}
            </div>
            <div className="floating-group select-floating w-100 mb-f">
              <DatePicker
                className="form-control"
                required
                selected={time}
                onChange={(date) => setTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText="hh:mm"
                disabled={isBookingDone?.isSuccess || !date}
                timeIntervals={60}
                minTime={minTime}
                maxTime={maxTime}
              />
              <label htmlFor="time" className="form-label label-text">
                Choose Time
              </label>
              {/* <div className="icon-form">
                <i className="fa fa-clock-o"></i>
              </div> */}
            </div>
            <div className="floating-group select-floating w-100 mb-f">
              <select
                id="inputState"
                className="form-select st"
                onChange={(e) => {
                  setNoOfParticipants(e.target.value);
                  setFormData({
                    fullName: '',
                    email: '',
                    phoneNo: '',
                    gender: '',
                  });
                }}
                value={noOfParticipants}
                required
                disabled={isBookingDone?.isSuccess}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
              </select>
              <label htmlFor="time" className="form-label label-text ">
                No. of Participants
              </label>
            </div>
          </div>
          {noOfParticipants === '2' && (
            <div className="d-flex gap-md-3 pb-0 m-b">
              <div className="floating-group select-floating w-100 mb-f">
                <input
                  id="inputState"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  name="fullName"
                  disabled={isBookingDone?.isSuccess}
                  type="text"
                  className={'form-control input-outline'}
                  required
                />
                <label htmlFor="time" className="form-label label-text ">
                  Full Name
                </label>
              </div>

              <div className="floating-group select-floating w-100 mb-f">
                <input
                  id="inputState"
                  value={formData.email}
                  onChange={handleInputChange}
                  name="email"
                  disabled={isBookingDone?.isSuccess}
                  type="email"
                  className={'form-control input-outline'}
                  required
                />
                <label htmlFor="time" className="form-label label-text ">
                  Email
                </label>
              </div>
              <div className="floating-group select-floating w-100 mb-f">
                <input
                  type="tel"
                  maxLength="10"
                  minLength="10"
                  onKeyPress={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  id="inputState"
                  disabled={isBookingDone?.isSuccess}
                  className={'form-control input-outline'}
                  required
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                  name="phoneNo"
                />
                <label htmlFor="time" className="form-label label-text ">
                  Mobile
                </label>
              </div>
              <div className="floating-group select-floating w-100 mb-f">
                <select
                  id="inputState"
                  className="form-select st"
                  value={formData.gender}
                  onChange={handleInputChange}
                  name="gender"
                  required
                  disabled={isBookingDone?.isSuccess}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Non_Binary">Non Binary</option>
                </select>
                <label htmlFor="time" className="form-label label-text ">
                  Gender
                </label>
              </div>
            </div>
          )}

          <div className="d-flex gap-md-3 pb-0 m-b">
            <div className="floating-group select-floating w-100 mb-f">
              <select
                id="inputState"
                className="form-select st"
                onChange={(e) => setSkillLevel(e.target.value)}
                value={skillLevel}
                required
                disabled={isBookingDone?.isSuccess}
              >
                <option value="Novice/Never Ever">Novice/Never Ever</option>
                <option value="Beginner/In-training">
                  Beginner/In-training
                </option>
                <option value="Proficient/Intermediate">
                  Proficient/Intermediate
                </option>
                <option value="Advanced/Specialist">Advanced/Specialist</option>
                <option value="Professional">Professional </option>
              </select>
              <label htmlFor="time" className="form-label label-text ">
                Skill Level
              </label>
            </div>
            <div className="floating-group select-floating w-100 mb-f">
              <select
                id="inputState"
                className="form-select st"
                onChange={(e) => setFirearm(e.target.value)}
                value={firearm}
                required
                disabled={isBookingDone?.isSuccess}
              >
                <option value="Handgun">Handgun</option>
                <option value="Rifle">Rifle </option>
                <option value="Shotgun">Shotgun </option>
              </select>
              <label htmlFor="time" className="form-label label-text ">
                Firearm
              </label>
            </div>
          </div>
          <div className="d-flex pb-0 m-b">
            <div className="floating-group select-floating w-100 mb-f">
              <textarea
                className="form-control input-outline"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                disabled={isBookingDone?.isSuccess}
              ></textarea>
              <label htmlFor="time" className="form-label label-text">
                Note
              </label>
            </div>
          </div>
          <div className="d-flex">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e) => setRangePolicy(e.target.checked)}
                id="gridCheck"
                checked={rangePolicy}
                disabled={isBookingDone?.isSuccess}
              />
              <label className="form-check-label" htmlFor="gridCheck">
                I agree to the{' '}
                <Link target="_blank" to="/range-policy">
                  Range Policies
                </Link>
                .
              </label>
            </div>
          </div>
          {isBookingDone.msgText && (
            <p
              className={
                isBookingDone?.isSuccess
                  ? 'text-center text-success mt-3'
                  : 'text-center text-danger  mt-3'
              }
            >
              {isBookingDone.msgText}
            </p>
          )}
        </div>

        <div className="modal-footer border-0">
          {!isBookingDone?.isSuccess && (
            <button
              type="button"
              className={
                'book-now-m book-now ' + (isBookLoading ? ' disabled-btn' : '')
              }
              disabled={isBookLoading}
              onClick={handleManualSubmit}
            >
              {isBookLoading ? (
                <ThreeDots
                  height="21"
                  width="90"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    id="Layer_1"
                    data-name="Layer 1"
                    fill="#fff"
                    className="cc"
                  >
                    <line
                      className="cls-1"
                      x1="12"
                      y1="0.5"
                      x2="12"
                      y2="5.29"
                    />
                    <line
                      className="cls-1"
                      x1="12"
                      y1="18.71"
                      x2="12"
                      y2="23.5"
                    />
                    <line
                      className="cls-1"
                      x1="23.5"
                      y1="12"
                      x2="18.71"
                      y2="12"
                    />
                    <line
                      className="cls-1"
                      x1="5.29"
                      y1="12"
                      x2="0.5"
                      y2="12"
                    />
                    <line
                      className="cls-1"
                      x1="20.13"
                      y1="3.87"
                      x2="16.74"
                      y2="7.26"
                    />
                    <line
                      className="cls-1"
                      x1="7.26"
                      y1="16.74"
                      x2="3.87"
                      y2="20.13"
                    />
                    <line
                      className="cls-1"
                      x1="20.13"
                      y1="20.13"
                      x2="16.74"
                      y2="16.74"
                    />
                    <line
                      className="cls-1"
                      x1="7.26"
                      y1="7.26"
                      x2="3.87"
                      y2="3.87"
                    />
                  </svg>{' '}
                  Book Now
                </>
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

RangeBookingForm.propTypes = {
  selectedRange: PropTypes.object,
  setTimeoutId: PropTypes.func,
  timeoutId: PropTypes.number,
};

export default RangeBookingForm;
